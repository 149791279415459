interface Config {
  apiBase: string;
  kvasirBase: string;
  appBase: string;
  sentryDsn: string;
  environment: string;
  matterModeEnabled?: boolean;
}

declare global {
  interface Window {
    precisely: Config;
  }
}

const config = { ...window.precisely };

export function KVASIR_BASE_URL(): string {
  return config.kvasirBase;
}

export function API_BASE_URL(): string {
  return config.apiBase;
}

export function APP_BASE_URL(): string {
  return config.appBase;
}

export const MATTER_MODE_ENABLED = !!config.matterModeEnabled;

export const SENTRY_DSN = config.sentryDsn;
export const ENVIRONMENT = config.environment;

export function SetAPIBaseURL(url: string) {
  config.apiBase = url;
}

export function SetKvasirBaseURL(url: string) {
  config.kvasirBase = url;
}

export function SetAPPBaseURL(url: string) {
  config.appBase = url;
}
