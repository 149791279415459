import { StateRouter } from '../app/stateRouter/StateRouter';
import { StateRoute } from '../app/stateRouter/StateRoute';
import { NewProjectPage } from './Projects/New/NewProject';
import { NewProjectSuccess } from './Projects/New/Success/NewProjectSuccess';
import { NavHeader } from '../app/NavHeader';
import { Home } from './Home/Home';
import { UpdateProjectPage } from './Projects/Update/UpdateProject';
import { ProjectUpdateSuccess } from './Projects/Update/Success/UpdateProjectSuccess';
import { ArchiveUploadPage } from './Archive/Upload/ArchiveUploadPage';
import { ArchiveUploadSuccessPage } from './Archive/Upload/Success/ArchiveUploadSuccessPage';

export const Taskpane = () => {
  return (
    <StateRouter initialRoute="/home">
      <NavHeader />
      <StateRoute path="/home">
        <Home />
      </StateRoute>
      <StateRoute path="/projects/new">
        <NewProjectPage />
      </StateRoute>
      <StateRoute path="/projects/new/success">
        <NewProjectSuccess />
      </StateRoute>
      <StateRoute path="/projects/update">
        <UpdateProjectPage />
      </StateRoute>
      <StateRoute path="/projects/update/success">
        <ProjectUpdateSuccess />
      </StateRoute>
      <StateRoute path="/archive/upload">
        <ArchiveUploadPage />
      </StateRoute>
      <StateRoute path="/archive/upload/success">
        <ArchiveUploadSuccessPage />
      </StateRoute>
    </StateRouter>
  );
};
