import {
  ISelectableOption,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { OrganizationReadOnly } from '../../../features/organizations/OrganizationReadOnly.tsx/OrganizationReadOnly';
import { FolderSelect } from '../../../features/folders/FolderSelect/FolderSelect';
import { useOrganizationsStore } from '../../../features/organizations/store';
import { DocumentsCheckboxList } from '../../../features/documents';
import { useOfficeStore } from '../../../officeStore';
import { useAuthenticationStore } from '../../../app/store';
import pagesMessages from '../../messages';
import { useStateRouterStore } from '../../../app/stateRouter/useStateRouterStore';
import { rootFolderId } from '../../../features/folders/FolderSelect/types';

export const ArchiveUploadPage: FC = () => {
  const intl = useIntl();

  const [selectedFolder, setSelectedFolder] = useState<
    ISelectableOption | undefined
  >();
  const { setCurrentRoute } = useStateRouterStore();

  const { selectedOrganizationOption: selectedOrganization } =
    useOrganizationsStore();
  const { conversationId } = useOfficeStore();

  const { officeToken } = useAuthenticationStore();
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const handleUpdateLoading = useCallback((loadingFiles: boolean) => {
    setLoadingFiles(loadingFiles);
  }, []);

  return (
    <Stack
      tokens={{
        childrenGap: 20,
      }}
      style={{
        marginBottom: '1em',
      }}>
      <Stack.Item>
        <p style={{ marginBottom: 'unset' }}>
          {intl.formatMessage(messages.archiveUploadDescription)}
        </p>
      </Stack.Item>
      <Stack.Item>
        <OrganizationReadOnly
          style={{
            margin: 'unset',
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <FolderSelect
          organizationId={String(selectedOrganization?.key)}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
        />
      </Stack.Item>
      <Stack.Item styles={{ root: { flexGrow: 1 } }}>
        {
          <DocumentsCheckboxList
            token={officeToken}
            conversationId={conversationId}
            updateLoading={handleUpdateLoading}
            includeEmlFile={false}
          />
        }
        {loadingFiles && (
          <Spinner
            size={SpinnerSize.large}
            label={intl.formatMessage(pagesMessages.loadingAttachments)}
          />
        )}
      </Stack.Item>
      <Stack.Item
        style={{
          marginTop: '1rem',
        }}>
        <PrimaryButton
          data-testid="upload-to-archive-button"
          disabled={!selectedOrganization || !selectedFolder || loadingFiles}
          onClick={() => {
            setCurrentRoute('/archive/upload/success', {
              title: selectedFolder?.title,
              organizationId: selectedOrganization.key,
              folderId: selectedFolder?.key,
            });
          }}>
          {intl.formatMessage(messages.uploadToArchiveButton)}
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
};
