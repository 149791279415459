import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import useFetch from 'use-http';
import {
  featureFlagTypeGuard,
  type Organization,
  type OrganizationOption,
  type UserAgentOrganizationSettings,
} from '.';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { API_BASE_URL } from '../../config';
import {
  MessageBar,
  MessageBarType,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { useOrganizationsStore } from './store';

export const OrganizationSelect = ({
  onChange,
  selected,
  isDisabled = false,
}: {
  onChange: (e: FormEvent<HTMLDivElement>, o: OrganizationOption) => void;
  selected: OrganizationOption;
  isDisabled?: boolean;
}): JSX.Element => {
  const {
    loading,
    error,
    data = [],
  } = useFetch<Organization[] | null>(
    API_BASE_URL() + '/organizations',
    {},
    [],
  );

  const intl = useIntl();

  const { load, loadOrganizationSettings, loadOrganizationSettingsMeta } =
    useOrganizationsStore();

  const selectedOrganization = useOrganizationsStore(
    state => state.selectedOrganizationOption,
  );
  const orgs = useOrganizationsStore(state => state.organizations);

  const { get } = useFetch<UserAgentOrganizationSettings | null>(
    API_BASE_URL() +
      `/user_agents/1/organizations/${selectedOrganization?.key}/settings`,
    {},
  );

  const asyncGet = useCallback(async () => {
    try {
      const response = await get();
      const featureFlags = response?.featureFlags;

      if (response && featureFlagTypeGuard(featureFlags)) {
        loadOrganizationSettings(featureFlags);
        loadOrganizationSettingsMeta({
          success: true,
          error: '',
          done: true,
        });
      }
    } catch (e) {
      loadOrganizationSettingsMeta({
        success: false,
        error: e.message,
        done: true,
      });
    }
  }, [get, loadOrganizationSettings]);

  useEffect(() => {
    if (selectedOrganization?.key) {
      asyncGet();
    }
  }, [selectedOrganization?.key, asyncGet]);

  const orgOptions = useMemo(
    () =>
      !error
        ? orgs.map(o => {
            if (o.subscriptionStatus === 'needs renewal') {
              return {
                key: o.id,
                text: o.name,
                title: intl.formatMessage(messages.needsRenewal),
                disabled: true,
              };
            }
            return {
              key: o.id,
              text: o.name,
              title: o.name,
            };
          })
        : [],
    [orgs],
  );

  useEffect(() => {
    load(data);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (data && data.length > 0 && !selected) {
      onChange(null, {
        key: data[0].id,
        text: data[0].name,
        title: data[0].name,
      });
    }
  }, [data, onChange, selected]);

  return (
    <>
      {loading && (
        <Spinner
          label={intl.formatMessage(messages.loading)}
          size={SpinnerSize.large}
        />
      )}
      {!error && (
        <Dropdown
          options={orgOptions.sort(x => {
            return x.text ? 1 : -1;
          })}
          placeholder={intl.formatMessage(messages.select)}
          label={intl.formatMessage(messages.select)}
          onChange={onChange}
          selectedKey={selected?.key}
          calloutProps={{
            doNotLayer: true,
            calloutMaxHeight: 300,
          }}
          notifyOnReselect={true}
          responsiveMode={ResponsiveMode.large}
          disabled={isDisabled}
        />
      )}

      {error && error.name !== '401' && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
          <p>{intl.formatMessage(messages.error)}</p>
        </MessageBar>
      )}
      {error && error.name === '401' && (
        <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
          <small>{intl.formatMessage(messages.unauthorizedDescription)}</small>
        </MessageBar>
      )}
    </>
  );
};
