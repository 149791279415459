import { Stack } from '@fluentui/react';
import { useStateRouterState } from '../../../../app/stateRouter/useStateRouterStore';
import { useDocumentsStore } from '../../../../features/documents/store';
import { useNavState } from '../../../../app/NavState';
import { useEffect } from 'react';
import { ArchiveUploadProgress } from '../../../../features/archive/ArchiveUploadProgress';

export const ArchiveUploadSuccessPage: React.FC = () => {
  const { folderId, organizationId } = useStateRouterState<{
    organizationId: string;
    folderId: string;
  }>();

  if (!organizationId || !folderId) {
    window.location.replace('/archive/upload');
  }

  const { setBackDisabled } = useNavState();

  useEffect(() => {
    setBackDisabled(true);
  }, [setBackDisabled]);

  const selectedDocuments = useDocumentsStore(
    state => state.computed.selectedDocuments,
  );

  return (
    <Stack tokens={{ childrenGap: 20 }} verticalFill>
      <ArchiveUploadProgress
        orgId={organizationId}
        folderId={folderId}
        files={selectedDocuments}
        updateLoading={(enabled: boolean) => {
          setBackDisabled(!enabled);
        }}
      />
    </Stack>
  );
};
