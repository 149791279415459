import { useCallback, useEffect, useState } from 'react';
import { Link, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DocumentsCheckboxList } from '../../../features/documents/DocumentsCheckboxList';
import {
  useDocumentsStore,
  useEmlStore,
} from '../../../features/documents/store';
import { useOfficeStore } from '../../../officeStore';
import { useIntl } from 'react-intl';
import pagesMessages from '../../messages';
import updateMessages from './messages';
import { useAuthenticationStore } from '../../../app/store';
import { useStateRouterStore } from '../../../app/stateRouter/useStateRouterStore';
import { ProjectSelect } from '../../../features/projects/ProjectSelect/ProjectSelect';
import { useOrganizationsStore } from '../../../features/organizations/store';
import { OrganizationReadOnly } from '../../../features/organizations/OrganizationReadOnly.tsx/OrganizationReadOnly';

export const UpdateProjectPage = () => {
  const [selectedProject, setSelectedProject] = useState<ProjectOption>();
  const { clearSelection } = useDocumentsStore();
  const { clear: clearEMLFiles } = useEmlStore();
  const { selectedOrganizationOption: selectedOrganization } =
    useOrganizationsStore();

  const { setCurrentRoute } = useStateRouterStore();

  const [_, setDraftProject] = useState<boolean>(false);

  const { conversationId } = useOfficeStore();

  const { officeToken } = useAuthenticationStore();

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const intl = useIntl();

  useEffect(() => {
    setDraftProject(false);
    clearSelection();
    clearEMLFiles();
  }, [clearEMLFiles, clearSelection, conversationId]);

  const handleUpdateLoading = useCallback((loadingFiles: boolean) => {
    setLoadingFiles(loadingFiles);
  }, []);

  return (
    <>
      <p>
        {intl.formatMessage(updateMessages.updateDescription)}{' '}
        <Link
          target="_blank"
          href="https://help.precisely.se/en/precisely-microsoft-outlook-e-mail-integration">
          {intl.formatMessage(pagesMessages.learnMore)}
        </Link>
      </p>

      <Stack
        tokens={{ childrenGap: 20 }}
        style={{
          marginBottom: '1em',
        }}>
        <Stack.Item>
          <OrganizationReadOnly
            style={{
              margin: 'unset',
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <ProjectSelect
            organizationId={selectedOrganization?.key}
            isDisabled={!selectedOrganization}
            setSelectedProject={setSelectedProject}
            selectedProject={selectedProject}
          />
        </Stack.Item>
        <Stack.Item styles={{ root: { flexGrow: 1 } }}>
          {
            <DocumentsCheckboxList
              token={officeToken}
              conversationId={conversationId}
              updateLoading={handleUpdateLoading}
            />
          }
          {loadingFiles && (
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage(pagesMessages.loadingAttachments)}
            />
          )}
        </Stack.Item>
      </Stack>
      <PrimaryButton
        data-testid="create-project"
        disabled={!selectedOrganization || !selectedProject || loadingFiles}
        onClick={() => {
          setCurrentRoute('/projects/update/success', {
            title: selectedProject?.title,
            projectId: selectedProject.key,
            organizationId: selectedOrganization.key,
          });
        }}
        style={{
          marginTop: 'auto',
        }}>
        {intl.formatMessage(updateMessages.submitButton)}
      </PrimaryButton>
    </>
  );
};
