export interface Organization {
  id: number;
  name: string;
  subscriptionStatus: 'active' | 'needs renewal';
}

export const FeatureFlags = {
  CAN_MANAGE_DOCUMENTS_DURING_FINAL_APPROVAL:
    'canManageDocumentsDuringFinalApproval',
  CAN_UPLOAD_DOCUMENTS: 'canUploadDocuments',
  CAN_UPLOAD_TO_ARCHIVE: 'canUploadToArchive',
} as const;

export type FeatureFlagsType = {
  [key in keyof typeof FeatureFlags]?: boolean;
};

export type UserAgentOrganizationSettings = {
  userAgentId: number | string;
  userAgentName: string;
  organizationId: number | string;
  featureFlags: Record<string, boolean>;
};

export const featureFlagTypeGuard = (
  obj: Record<string, unknown>,
): obj is FeatureFlagsType => {
  return Object.values(FeatureFlags).every(
    key => typeof obj[key] === 'boolean',
  );
};

export type OrganizationOption = {
  key: string | number;
  text: string;
  title: string;
};
