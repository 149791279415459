import { ReactNode } from 'react';
import { useStateRouterStore } from './useStateRouterStore';

export const StateRoute = ({
  children,
  path,
}: {
  children: ReactNode;
  path: string;
}) => {
  const { currentRoute } = useStateRouterStore();
  if (currentRoute !== path) return null;
  return <>{children}</>;
};
