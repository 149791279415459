import { TemplateSelect } from '../../../features/templates/TemplateSelect';
import { useCallback, useEffect, useState } from 'react';
import { Link, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DocumentsCheckboxList } from '../../../features/documents/DocumentsCheckboxList';
import { TemplateOption } from '../../../features/templates';
import {
  useDocumentsStore,
  useEmlStore,
} from '../../../features/documents/store';
import { useOfficeStore } from '../../../officeStore';
import { useIntl } from 'react-intl';
import messages from '../../messages';
import { useAuthenticationStore } from '../../../app/store';
import { useStateRouterStore } from '../../../app/stateRouter/useStateRouterStore';
import { useOrganizationsStore } from '../../../features/organizations/store';
import { OrganizationReadOnly } from '../../../features/organizations/OrganizationReadOnly.tsx/OrganizationReadOnly';

export const NewProjectPage = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateOption>();
  const { clearSelection } = useDocumentsStore();
  const { clear: clearEMLFiles } = useEmlStore();

  const { setCurrentRoute } = useStateRouterStore();

  const [_, setDraftProject] = useState<boolean>(false);

  const { conversationId } = useOfficeStore();

  const { officeToken } = useAuthenticationStore();

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const intl = useIntl();

  useEffect(() => {
    setDraftProject(false);
    clearSelection();
    clearEMLFiles();
  }, [clearEMLFiles, clearSelection, conversationId]);

  const handleUpdateLoading = useCallback((loadingFiles: boolean) => {
    setLoadingFiles(loadingFiles);
  }, []);

  const { selectedOrganizationOption } = useOrganizationsStore();

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        style={{
          marginBottom: '1em',
        }}>
        <Stack.Item>
          <p style={{ marginBottom: 'unset' }}>
            {intl.formatMessage(messages.startBySelectingTemplate)}{' '}
            <Link
              target="_blank"
              href="https://help.precisely.se/en/precisely-microsoft-outlook-e-mail-integration">
              {intl.formatMessage(messages.learnMore)}
            </Link>
          </p>
        </Stack.Item>
        <Stack.Item>
          <OrganizationReadOnly
            style={{
              margin: 'unset',
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <TemplateSelect
            organizationId={selectedOrganizationOption?.key}
            isDisabled={false}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
          />
        </Stack.Item>
        <Stack.Item styles={{ root: { flexGrow: 1 } }}>
          {
            <DocumentsCheckboxList
              token={officeToken}
              conversationId={conversationId}
              updateLoading={handleUpdateLoading}
            />
          }
          {loadingFiles && (
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage(messages.loadingAttachments)}
            />
          )}
        </Stack.Item>
      </Stack>
      <PrimaryButton
        data-testid="create-project"
        disabled={
          !selectedOrganizationOption || !selectedTemplate || loadingFiles
        }
        onClick={() => {
          setCurrentRoute('/projects/new/success', {
            templateId: selectedTemplate.key,
            organizationId: selectedOrganizationOption.key,
          });
        }}
        style={{
          marginTop: 'auto',
        }}>
        {intl.formatMessage(messages.createProject)}
      </PrimaryButton>
    </>
  );
};
