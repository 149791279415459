import { create } from 'zustand';

interface StateRouterStore<S> {
  currentRoute: string | null;
  state: S;
  history: {
    route: string;
    state: Record<string, unknown>;
  }[];
  setCurrentRoute: (route: string, state?: Record<string, unknown>) => void;
}

export const useStateRouterStore = create<StateRouterStore<any>>(set => ({
  currentRoute: null,
  state: {},
  history: [],
  setCurrentRoute: (route, state = {}) => set({ currentRoute: route, state }),
}));

const stateSelector: <S>(state: StateRouterStore<S>) => S = state =>
  state.state;

export const useStateRouterState = <S,>(): S =>
  useStateRouterStore(stateSelector<S>);
