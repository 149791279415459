import { useEffect } from 'react';
import { Route, Switch } from 'wouter';
import { Taskpane } from '../pages/Taskpane';
import Login from '../pages/Login';
import { useOfficeStore } from '../officeStore';
import '../index.css';
import * as Sentry from '@sentry/react';
import { API_BASE_URL, ENVIRONMENT, SENTRY_DSN } from '../config';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { useIntl } from 'react-intl';
import Intl from '../Intl';
import messages from '../messages';
import { Provider } from 'use-http';
import { useFetchOptions } from '../hooks/useFetchOptions';
import { AuthenticationProvider } from './AuthenticationProvider';
import { ErrorBoundary } from './ErrorBoundary';

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: ENVIRONMENT,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: true,
      stickySession: true,
    }),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
});

interface IAppProps {
  officeInitialized: boolean;
  language: string;
}

export const App: React.FC<IAppProps> = (props: IAppProps): JSX.Element => {
  const { load } = useOfficeStore(({ isOfficeInitialized, load }) => ({
    isOfficeInitialized,
    load,
  }));

  useEffect(() => {
    load(props.officeInitialized);
  }, [load, props.officeInitialized]);

  return (
    <ErrorBoundary>
      <FluentProvider
        theme={teamsLightTheme}
        style={{
          height: 'calc(100% - 22px)',
          margin: 22,
          marginTop: 0,
        }}>
        <Intl language={props.language}>
          <Routes />
        </Intl>
      </FluentProvider>
    </ErrorBoundary>
  );
};

const Routes = () => {
  const intl = useIntl();
  const fetchOptions = useFetchOptions();

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <AuthenticationProvider>
          <Provider url={API_BASE_URL()} options={fetchOptions}>
            <Route path="/taskpane" component={Taskpane} />
          </Provider>
        </AuthenticationProvider>
        <Route>{intl.formatMessage(messages.fourOhFourMessage)}</Route>
      </Switch>
    </>
  );
};

export default App;
