import {
  ComboBox,
  IComboBoxProps,
  ISelectableOption,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { useFetch } from 'use-http';
import { API_BASE_URL } from '../../../config';
import { useCallback, useEffect, useState } from 'react';
import { Folder, rootFolderId } from './types';
import { useIntl } from 'react-intl';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import { messages } from './messages';

const rawFoldersToOptions = (
  foldersRaw: Folder[] | null,
  rootFolderName: string = 'Archive',
): ISelectableOption<{ path: string }>[] => {
  const rootFolder = {
    key: rootFolderId,
    text: rootFolderName,
    data: {
      path: `>`,
    },
  };

  if (!foldersRaw) return [rootFolder];

  return [rootFolder].concat(
    foldersRaw.map(folder => ({
      key: folder.id,
      text: folder.name,
      data: {
        path: (() => {
          if (!folder.parentFolders || folder.parentFolders.length === 0) {
            return `${rootFolderName} >`;
          }
          return `> ${rootFolderName} > ${folder.parentFolders
            .map(f => f.name)
            .join(' > ')} >`;
        })(),
      },
    })),
  );
};

type Props = {
  organizationId: string;
  selectedFolder: ISelectableOption;
  setSelectedFolder: (folder: ISelectableOption) => void;
};

enum SearchState {
  Searching,
  Empty,
  Error,
  Hits,
}

export const FolderSelect = ({
  organizationId,
  selectedFolder = {
    key: '',
    text: '',
    data: {},
  },
  setSelectedFolder,
}: Props) => {
  const {
    post,
    error,
    response: postFoldersResponse,
  } = useFetch<Folder[] | null>(
    API_BASE_URL() + `/organizations/${organizationId}/folders/search`,
    {},
  );

  const [folderOptions, setFolderOptions] = useState<
    ISelectableOption[] | null
  >([]);
  const [searchState, setSearchState] = useState<SearchState>();

  const intl = useIntl();

  const postSearchAsync = useCallback(
    async searchText => {
      try {
        const response = await post({
          searchText,
        });

        if (postFoldersResponse.ok) {
          setFolderOptions(
            rawFoldersToOptions(
              response,
              intl.formatMessage(messages.rootFolderName),
            ),
          );
          setSearchState(SearchState.Hits);
        } else {
          setFolderOptions([
            {
              key: rootFolderId,
              text: intl.formatMessage(messages.rootFolderName),
              data: {
                path: `${messages.rootFolderName} >`,
              },
            },
          ]);
          setSearchState(SearchState.Empty);
        }
      } catch (e) {
        setSearchState(SearchState.Error);
        console.error(e);
      }
    },
    [post, setFolderOptions, setSearchState],
  );

  const debouncedSearch = useDebouncedCallback(postSearchAsync, 500);

  useEffect(() => {
    if (!organizationId) return;

    try {
      postSearchAsync('');
    } catch (e) {
      console.error(e);
    }
  }, [organizationId, postSearchAsync]);

  const handleOptionChange: IComboBoxProps['onChange'] = (
    _,
    option,
    __,
    ___,
  ) => {
    setSelectedFolder(option);

    let key = option?.key;
    const folderOption = folderOptions.find(option => option.key === key);

    if (folderOption) {
      setSelectedFolder(folderOption);
    }
  };

  const handleInputChange: IComboBoxProps['onInputValueChange'] = (
    searchText: string,
  ) => {
    if (!organizationId) return;
    debouncedSearch(searchText);
  };

  const placeholder =
    searchState === SearchState.Empty
      ? intl.formatMessage(messages.folderSelectNoResults)
      : intl.formatMessage(messages.folderSelectPlaceholder);

  const onRenderOption = (option: ISelectableOption) => {
    return (
      <div style={{ marginTop: '4px' }}>
        <div>{option.text}</div>
        {option.data?.path && (
          <small>
            <i>{option.data?.path}</i>
          </small>
        )}
      </div>
    );
  };

  return (
    <>
      {!error && (
        <ComboBox
          selectedKey={[String(selectedFolder.key)]}
          onChange={handleOptionChange}
          onInputValueChange={handleInputChange}
          allowFreeInput={true}
          autoComplete="off"
          options={folderOptions}
          label={intl.formatMessage(messages.folderSelectLabel)}
          placeholder={placeholder}
          onRenderOption={onRenderOption}
          calloutProps={{
            doNotLayer: true,
            calloutMaxHeight: 300,
            calloutMinWidth: '100%' as unknown as number,
          }}
        />
      )}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
          <p>{intl.formatMessage(messages.folderSelectError)}</p>
        </MessageBar>
      )}
    </>
  );
};
