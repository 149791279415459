import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../../../utils/intlUtils';

const intlMessages = defineMessages({
  selectProjectLabel: {
    defaultMessage: 'Select a project',
  },
  selectProjectLabel_Matter: {
    defaultMessage: 'Select a matter',
  },
  loadingProjects: {
    defaultMessage: 'loading projects',
  },
  loadingProjects_Matter: {
    defaultMessage: 'loading matters',
  },
  noProjectsFound: {
    defaultMessage: 'No projects found',
  },
  noProjectsFound_Matter: {
    defaultMessage: 'No matters found',
  },
  close: {
    defaultMessage: 'Close',
  },
  errorLoadingProjects: {
    defaultMessage: 'There was an error loading the projects',
  },
  errorLoadingProjects_Matter: {
    defaultMessage: 'There was an error loading the matters',
  },
});

const messages = {
  ...intlMessages,
  get selectProjectLabel() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.selectProjectLabel_Matter;
    } else {
      return intlMessages.selectProjectLabel;
    }
  },
  get loadingProjects() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.loadingProjects_Matter;
    } else {
      return intlMessages.loadingProjects;
    }
  },
  get noProjectsFound() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.noProjectsFound_Matter;
    } else {
      return intlMessages.noProjectsFound;
    }
  },
  get errorLoadingProjects() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.errorLoadingProjects_Matter;
    } else {
      return intlMessages.errorLoadingProjects;
    }
  },
};

export default messages;
