import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  uploadingDocuments: {
    defaultMessage: 'Uploading files...',
  },
  uploadSuccess: {
    defaultMessage:
      'Files archived successfully! You can keep uploading files from other email threads.',
  },
  openFolderInPrecisely: {
    defaultMessage: 'Open the folder in Precisely',
  },
});
