import { useFetch } from 'use-http';
import { DocumentView } from '../documents';
import { APP_BASE_URL } from '../../config';
import { useEffect, useState } from 'react';
import { Link, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { rootFolderId } from '../folders/FolderSelect/types';

type Props = {
  orgId: string | number;
  folderId: string | number;
  files: DocumentView[];
  updateLoading: (enabled: boolean) => void;
};

export const ArchiveUploadProgress = ({
  orgId,
  folderId,
  files,
  updateLoading,
}: Props) => {
  const intl = useIntl();
  const [importDone, setImportDone] = useState<boolean>(false);

  // upload documents to the archive endpoint
  const { post } = useFetch<Document[]>(
    `organizations/${orgId}/documents/imports`,
  );

  useEffect(() => {
    if (importDone) {
      updateLoading(true);
    }
  }, [importDone]);

  useEffect(() => {
    async function createFormDataImportedDocuments(documents: DocumentView[]) {
      const data = new FormData();
      const dataParts = await Promise.all(
        documents
          .filter(doc => doc.attachment)
          .map(document => {
            return document.content;
          }),
      );

      dataParts.forEach(part => {
        data.append('file', part, part.name);
      });
      if (folderId !== rootFolderId) {
        data.append('folderId', folderId ? folderId.toString() : '');
      }
      return data;
    }

    const postDocuments = async () => {
      updateLoading(true);
      const formDataDocuments = await createFormDataImportedDocuments(files);
      if (formDataDocuments.has('file')) {
        await post(formDataDocuments);
      }
      updateLoading(false);
      setImportDone(true);
    };

    postDocuments();
  }, []);

  const folderUrlQuery =
    folderId === rootFolderId ? '' : `?folderId=${folderId}`;

  return (
    <>
      {!importDone && (
        <Stack.Item style={{ marginTop: 20 }}>
          <Spinner
            size={SpinnerSize.large}
            label={intl.formatMessage(messages.uploadingDocuments)}
          />
        </Stack.Item>
      )}
      {importDone && (
        <>
          <Stack.Item>
            <p>{intl.formatMessage(messages.uploadSuccess)}</p>
          </Stack.Item>
          <Stack.Item>
            <Link
              target="_blank"
              href={`${APP_BASE_URL()}/archive${folderUrlQuery}`}>
              {intl.formatMessage(messages.openFolderInPrecisely)}
            </Link>
          </Stack.Item>
        </>
      )}
    </>
  );
};
