import { defineMessages } from 'react-intl';

export default defineMessages({
  unauthorizedDescription: {
    defaultMessage:
      'Please allow the add-in to display a new window, in order to proceed with authentication.',
  },
  loading: { defaultMessage: 'loading organizations' },
  select: { defaultMessage: 'Select an organization' },
  close: { defaultMessage: 'Close' },
  error: { defaultMessage: 'There was an error loading the organizations' },
  needsRenewal: { defaultMessage: 'Renew license to activate' },
  organization: { defaultMessage: 'Organization:' },
});
