import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  archiveUploadDescription: {
    defaultMessage:
      'To archive files in Precisely, start by selecting a folder you want to upload the files to. Then select which files to upload to the folder.',
  },
  uploadToArchiveButton: {
    defaultMessage: 'Upload to the archive',
  },
});
