import { ReactNode, useEffect } from 'react';
import { useOfficeStore } from '../officeStore';
import { useAuthenticationStore } from './store';
import { getValue, storeValue } from '../utils/storage';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useIntl } from 'react-intl';
import messages from '../pages/messages';
import { Link } from 'wouter';

export const AuthenticationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const intl = useIntl();

  const { setOfficeToken, officeToken, setPreciselyToken, preciselyToken } =
    useAuthenticationStore();

  const { isOfficeInitialized, setConversationId, conversationId } =
    useOfficeStore();

  useEffect(() => {
    setConversationId(Office.context?.mailbox?.item?.conversationId || null);
  }, [setConversationId]);

  useEffect(() => {
    if (!isOfficeInitialized || !Office.context?.mailbox) return;
    Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => {
      setConversationId(Office.context?.mailbox.item?.conversationId);
    });
  }, [isOfficeInitialized, setConversationId]);

  useEffect(() => {
    if (!isOfficeInitialized || !Office.context) return;

    const token: string = getValue('precisely-token');

    if (token) {
      setPreciselyToken(token);
    }

    Office.context?.mailbox?.getCallbackTokenAsync({ isRest: true }, result => {
      setOfficeToken(result.value);
      setConversationId(Office.context?.mailbox?.item?.conversationId);
      if (!token) {
        const email = Office.context.mailbox.userProfile.emailAddress;
        const displayInIframe = !navigator.userAgent.includes('Chrome');
        Office.context?.ui?.displayDialogAsync(
          window.location.origin + '/login?email=' + email,
          { height: 50, width: 50, displayInIframe: displayInIframe },
          function (result) {
            const dialog = result.value;
            if (dialog) {
              dialog.addEventHandler(
                Office.EventType.DialogMessageReceived,
                arg => {
                  if (arg && 'message' in arg) {
                    storeValue('precisely-token', arg.message);
                    dialog.close();
                    setPreciselyToken(arg.message);
                  }
                },
              );
            }
          },
        );
      }
    });
  }, [
    isOfficeInitialized,
    setConversationId,
    setOfficeToken,
    setPreciselyToken,
    preciselyToken,
  ]);

  if (preciselyToken && officeToken && conversationId) {
    return <>{children}</>;
  }

  if (preciselyToken && officeToken && !conversationId) {
    return (
      <p>
        {intl.formatMessage(messages.openAnEmailToStart)}{' '}
        <Link
          target="_blank"
          href="https://help.precisely.se/en/precisely-microsoft-outlook-e-mail-integration">
          {intl.formatMessage(messages.learnMore)}
        </Link>
      </p>
    );
  } else {
    return (
      <Spinner
        label={intl.formatMessage(messages.connecting)}
        ariaLive="assertive"
        labelPosition="left"
        size={SpinnerSize.large}
      />
    );
  }
};
