import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  rootFolderName: {
    defaultMessage: 'Archive',
  },
  folderSelectLabel: {
    defaultMessage: 'Select a folder',
  },
  folderSelectPlaceholder: {
    defaultMessage: 'Type to search or select an option',
  },
  folderSelectNoResults: {
    defaultMessage: 'No results found',
  },
  folderSelectError: {
    defaultMessage: 'There was an error loading the folders',
  },
  close: {
    defaultMessage: 'Close',
  },
});
