import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../../utils/intlUtils';

const intlMessages = defineMessages({
  draftingProject: { defaultMessage: 'Drafting project' },
  draftingProject_Matter: { defaultMessage: 'Drafting matter' },
  uploadingDocuments: { defaultMessage: 'Uploading documents' },
  failedToUploadDocuments: { defaultMessage: 'Failed to upload documents' },
  uploadDocumentsSuccess: {
    defaultMessage:
      'The project was created and the files were uploaded successfully.',
  },
  uploadDocumentsSuccess_Matter: {
    defaultMessage:
      'The matter was created and the files were uploaded successfully.',
  },
  uploadToExistingProjectSuccess: {
    defaultMessage: 'File(s) uploaded successfully! ',
  },
  openProject: { defaultMessage: 'Open project: {title}' },
  openProject_Matter: { defaultMessage: 'Open matter: {title}' },
  close: { defaultMessage: 'Close' },
});

const messages = {
  ...intlMessages,
  get draftingProject() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.draftingProject_Matter;
    } else {
      return intlMessages.draftingProject;
    }
  },
  get uploadDocumentsSuccess() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.uploadDocumentsSuccess_Matter;
    } else {
      return intlMessages.uploadDocumentsSuccess;
    }
  },
  get openProject() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.openProject_Matter;
    } else {
      return intlMessages.openProject;
    }
  },
};

export default messages;
