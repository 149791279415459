// react wrapper component that keeps state for the current "route" in use
// current route is only kept in a zustand store, not in the url

import { ReactNode } from 'react';
import { useStateRouterStore } from './useStateRouterStore';

export const StateRouter = ({
  children,
  initialRoute,
}: {
  children: ReactNode;
  initialRoute: string;
}) => {
  const { currentRoute, setCurrentRoute } = useStateRouterStore();

  if (currentRoute === null) {
    setCurrentRoute(initialRoute);
  }

  return <>{children}</>;
};
