import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DocumentView } from '.';

type Store = {
  documents: DocumentView[];
  addDocuments: (documents: DocumentView[]) => void;
  addDocument: (d: DocumentView) => void;
  removeDocument: (id: string) => void;
  setDocumentSelected: (id: string, isSelected: boolean) => void;
  setDocumentContent: (id: string, content: File) => void;
  clearSelection: () => void;
  computed: {
    selectedDocuments: DocumentView[];
  };
};

export const useDocumentsStore = create(
  immer<Store>((set, get) => ({
    documents: [],
    addDocuments: (documents: DocumentView[]) => {
      set(state => {
        state.documents.push(...documents);
      });
    },
    addDocument: (document: DocumentView) => {
      set(state => {
        let newDoc = true;
        state.documents.forEach(d => {
          if (d.md5 == document.md5) {
            newDoc = false;
          }
        });
        if (newDoc) {
          state.documents.push(document);
        }
      });
    },
    removeDocument: (id: string) => {
      set(state => {
        state.documents = state.documents.filter(d => d.id !== id);
      });
    },
    setDocumentSelected: (id, isSelected) => {
      set(state => {
        state.documents = state.documents.map(d => {
          if (d.id === id) {
            return { ...d, isSelected };
          }
          return d;
        });
      });
    },
    setDocumentContent: (id: string, content: File) => {
      set(state => {
        state.documents = state.documents.map(d => {
          if (d.id === id) {
            return { ...d, content };
          }
          return d;
        });
      });
    },
    clearSelection: () => {
      set(state => {
        state.documents = [];
      });
    },
    computed: {
      get selectedDocuments() {
        return get().documents.filter(d => d.isSelected);
      },
    },
  })),
);

type EMLStore = {
  files: File[];
  allFilesLoaded: boolean;
  addFile: (file: File) => void;
  clear: () => void;
};

export const useEmlStore = create(
  immer<EMLStore>((set, get) => ({
    files: [] as File[],
    allFilesLoaded: false,
    addFile: (file: File) => {
      set(state => {
        state.files.push(file);
      });
    },
    clear: () => {
      set(state => {
        state.files = [];
      });
    },
  })),
);
