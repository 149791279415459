import { createRoot } from 'react-dom/client';
import App from './app/App';

let isOfficeInitialized = false;

const supportedLocales = ['en', 'sv'];

const getLanguage = (languageFromContext: string) => {
  const [language] = languageFromContext.split('-');

  if (supportedLocales.includes(language)) return language;

  return 'en';
};

const container = document.getElementById('root');
const root = createRoot(container);

const render = Component => {
  const language = getLanguage(Office.context.displayLanguage);

  root.render(
    <Component officeInitialized={isOfficeInitialized} language={language} />,
  );
};

const LoadedOutsideOffice = () => {
  return (
    <div>
      <p>
        Looks like the add-in was loaded outside of Office. Please try again in
        a <strong>Office client</strong>
      </p>
    </div>
  );
};

/* Render application after Office initializes */
void Office.onReady(info => {
  if (info.host !== null && info.platform !== null) {
    if (window.console) {
      console.info(
        `Office.js is now ready in ${info.host} on ${info.platform}`,
      );
    }

    if (info !== null) {
      isOfficeInitialized = true;
      render(App);
      return;
    }
  } else {
    if (window.console) {
      console.warn('Office.js was loaded outside of Office');
    }
    root.render(<LoadedOutsideOffice />);
  }
});
