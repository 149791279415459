import { CachePolicies, IncomingOptions, Res } from 'use-http';
import * as Sentry from '@sentry/react';
import { getValue, storeValue } from '../utils/storage';
import { useAuthenticationStore } from '../app/store';
import { useMemo } from 'react';

export const useFetchOptions = () => {
  const localStorageToken = getValue('precisely-token') || '';
  const { preciselyToken, setPreciselyToken } = useAuthenticationStore();

  if (localStorageToken && !preciselyToken) {
    setPreciselyToken(localStorageToken);
  }

  const token = preciselyToken || localStorageToken;

  const options = useMemo(() => {
    const fetchOptions: IncomingOptions = {
      cachePolicy: CachePolicies.NO_CACHE,
      interceptors: {
        // every time we make an http request, this will run 1st before the request is made
        // url, path and route are supplied to the interceptor
        // request options can be modified and must be returned
        request: ({ options }) => {
          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`,
            'X-PRECISELY-CLIENT': 'outlook',
          };

          return options;
        },
        response: async ({ response }) => {
          if (response.status == 403 || response.status == 401) {
            const email = Office.context.mailbox.userProfile.emailAddress;
            const displayInIframe = !navigator.userAgent.includes('Chrome');
            Office.context.ui.displayDialogAsync(
              window.location.origin + '/login?email=' + email,
              { height: 50, width: 50, displayInIframe: displayInIframe },
              function (result) {
                const dialog = result.value;
                if (dialog) {
                  const messageHandler = (
                    arg:
                      | {
                          message: string;
                          origin: string | undefined;
                        }
                      | {
                          error: number;
                        },
                  ) => {
                    if ('error' in arg) {
                      return;
                    }
                    if (arg && arg.message) {
                      dialog.close();
                      storeValue('precisely-token', arg.message);
                    }
                    window.location.href = '/taskpane';
                  };
                  dialog.addEventHandler(
                    Office.EventType.DialogMessageReceived,
                    messageHandler,
                  );
                }
              },
            );
          }
          return response;
        },
      },
      onError: ({ error }) => {
        Sentry.captureException(error, {
          tags: {
            section: 'fetchUtils',
          },
        });
        console.error('error: ', error);
      },
    };

    return fetchOptions;
  }, [preciselyToken, token]);

  return options;
};
