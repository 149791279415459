import { create } from 'zustand';

type State = {
  backDisabled: boolean;
  setBackDisabled: (backDisabled: boolean) => void;
  reset: () => void;
};

export const useNavState = create<State>(set => ({
  backDisabled: false,
  setBackDisabled: backDisabled => set({ backDisabled }),
  reset: () => set({ backDisabled: false }),
}));
