import { ReactChild, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

const Intl = (props: {
  language: string;
  children: ReactChild;
}): JSX.Element => {
  const [messages, setMessages] = useState<Record<string, string>>(null);

  useEffect(() => {
    void import(`./messages/${props.language}.json`).then(
      (file: { default: Record<string, string> }) => {
        setMessages(file.default);
      },
    );
  }, [props.language]);

  if (!messages) return null;

  return (
    <IntlProvider
      messages={messages}
      locale={props.language}
      defaultLocale="en">
      {props.children}
    </IntlProvider>
  );
};

export default Intl;
