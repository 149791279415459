import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

const ErrorFallback = () => {
  return <div>Something went wrong!</div>;
};

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};
