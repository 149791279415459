import { create } from 'zustand';

interface Store {
  preciselyToken: string;
  setPreciselyToken: (token: string) => void;
  officeToken: string;
  setOfficeToken: (token: string) => void;
}

export const useAuthenticationStore = create<Store>(set => ({
  preciselyToken: '',
  setPreciselyToken: (token: string) => set({ preciselyToken: token }),
  officeToken: '',
  setOfficeToken: (token: string) => set({ officeToken: token }),
}));
