import { defineMessages } from 'react-intl';

export default defineMessages({
  loadingTemplates: { defaultMessage: 'loading templates' },
  noTemplateWithUploads: { defaultMessage: 'No template with uploads found' },
  selectTemplate: { defaultMessage: 'Select a template' },
  close: { defaultMessage: 'Close' },
  errorLoadingTemplates: {
    defaultMessage: 'There was an error loading the templates',
  },
});
