import { create } from 'zustand';
import {
  FeatureFlags,
  FeatureFlagsType,
  Organization,
  OrganizationOption,
  UserAgentOrganizationSettings,
} from '.';

type RequestMetaData = {
  success: boolean;
  error: string;
  done: boolean;
};

interface Store {
  organizations?: Organization[];
  load: (os: Organization[]) => void;
  selectedOrganizationOption: OrganizationOption | null;
  setSelectedOrganizationOption: (organizationId: number | string) => void;
  currentOrganizationSettings: FeatureFlagsType;
  loadOrganizationSettings: (settings: FeatureFlagsType) => void;
  loadOrganizationSettingsMeta: (requestMetaData: RequestMetaData) => void;
  organizationSettingsMeta: RequestMetaData;
}

export const useOrganizationsStore = create<Store>((set, get) => ({
  organizations: [],
  load: (organizations: Organization[]) => set({ organizations }),
  selectedOrganizationOption: null,
  setSelectedOrganizationOption: (organizationId: number) =>
    set(state => {
      if (!state.organizations.length) return state;
      const orgIndex = state.organizations.findIndex(
        o => o.id === organizationId,
      );
      const nextOrg = {
        key: state.organizations[orgIndex].id,
        text: state.organizations[orgIndex].name,
        title: state.organizations[orgIndex].name,
      };

      return { selectedOrganizationOption: nextOrg };
    }),
  currentOrganizationSettings: {},
  loadOrganizationSettings: featureFlags =>
    set(state => {
      state.currentOrganizationSettings = featureFlags;
      return state;
    }),
  loadOrganizationSettingsMeta: (requestMetaData: RequestMetaData) =>
    set({ organizationSettingsMeta: requestMetaData }),
  organizationSettingsMeta: { success: false, error: '', done: false },
}));
