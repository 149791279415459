import { useFetch } from 'use-http';
import { useStateRouterState } from '../../../../app/stateRouter/useStateRouterStore';
import { API_BASE_URL } from '../../../../config';
import { useDocumentsStore } from '../../../../features/documents/store';
import { UploadProgress } from '../../../../features/projects/UploadProgress';
import { Stack } from '@fluentui/react';
import { useEffect } from 'react';
import { useNavState } from '../../../../app/NavState';

export const ProjectUpdateSuccess = () => {
  const { projectId, organizationId, title } = useStateRouterState<{
    projectId: string;
    organizationId: string;
    title: string;
  }>();

  const selectedDocuments = useDocumentsStore(
    state => state.computed.selectedDocuments,
  );

  const { setBackDisabled } = useNavState();

  useEffect(() => {
    setBackDisabled(true);
  }, []);

  return (
    <Stack tokens={{ childrenGap: 20 }} verticalFill>
      <Stack.Item>
        <UploadProgress
          title="Uploading Documents"
          projectTitle={title}
          orgId={organizationId}
          projectId={projectId}
          files={selectedDocuments}
          conversationId={Office.context?.mailbox.item?.conversationId}
          updateLoading={(enabled: boolean) => {
            setBackDisabled(!enabled);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
