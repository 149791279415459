import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../../../utils/intlUtils';

const intlMessages = defineMessages({
  updateDescription: {
    defaultMessage:
      'To add files to an existing matter in Precisely, start by selecting an existing matter. Then select files to upload to the matter.',
  },
  submitButton: {
    defaultMessage: 'Upload to project',
  },
  submitButton_Matter: {
    defaultMessage: 'Upload to matter',
  },
});

const messages = {
  ...intlMessages,
  get submitButton() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.submitButton_Matter;
    } else {
      return intlMessages.submitButton;
    }
  },
};

export default messages;
