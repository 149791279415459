import {
  MessageBar,
  MessageBarType,
  ComboBox,
  IComboBoxProps,
} from '@fluentui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFetch from 'use-http';
import { API_BASE_URL } from '../../../config';
import { useIntl } from 'react-intl';
import messages from './messages';
import { ProjectSearchResponse } from '../types';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import { useOrganizationsStore } from '../../organizations/store';
import { FeatureFlags } from '../../organizations';

export const ProjectSelect = ({
  organizationId,
  isDisabled,
  setSelectedProject,
  selectedProject = { key: null, text: '', title: '' },
}: {
  organizationId: number | string;
  isDisabled: boolean;
  setSelectedProject: (t: ProjectOption) => void;
  selectedProject: ProjectOption;
}): JSX.Element => {
  const { post, error, data, response } = useFetch<ProjectSearchResponse>(
    API_BASE_URL() + `/organizations/${organizationId}/projects/search`,
    {},
  );

  const [projectOptions, setProjectOptions] = useState<ProjectOption[]>([]);

  const intl = useIntl();
  const { currentOrganizationSettings } = useOrganizationsStore();

  const matterStatusFilter = useMemo(() => {
    if (currentOrganizationSettings) {
      return currentOrganizationSettings[
        FeatureFlags.CAN_MANAGE_DOCUMENTS_DURING_FINAL_APPROVAL
      ]
        ? ['draft', 'review', 'initial_approval', 'final_approval']
        : ['draft', 'review', 'initial_approval'];
    }
    return ['draft', 'review', 'initial_approval'];
  }, [currentOrganizationSettings]);

  const postSearchAsync = useCallback(
    async searchText => {
      try {
        const response = await post({
          searchText,
          filters: [
            {
              k: 'matter_status',
              vs: matterStatusFilter,
            },
          ],
          projectDetailLevel: 'list',
        });

        if (response) {
          const projectOptions: ProjectOption[] = response.map(project => {
            return {
              key: `${project.id}`,
              text: project.title,
              title: project.title,
            };
          });

          setProjectOptions(projectOptions);
        }
      } catch (e) {
        // TODO: Error handled in component
        console.error(e);
      }
    },
    [organizationId, post, matterStatusFilter],
  );

  const debouncedSearch = useDebouncedCallback(postSearchAsync, 300);

  useEffect(() => {
    if (isDisabled || !organizationId) return;

    const postAsync = async () => {
      try {
        postSearchAsync('');
      } catch (e) {
        console.error(e);
      }
    };
    postAsync();
  }, [organizationId, isDisabled, post]);

  const handleOptionChange: IComboBoxProps['onChange'] = (
    _,
    option,
    __,
    ___,
  ) => {
    let key = option?.key;
    const projectOption = projectOptions.find(option => option.key === key);

    if (projectOption) {
      setSelectedProject(projectOption);
    }
  };

  const onInput: (searchText: string) => void = searchText => {
    if (!organizationId) return;
    debouncedSearch(searchText);
  };

  const placeholder =
    data === null && response.ok
      ? intl.formatMessage(messages.noProjectsFound)
      : intl.formatMessage(messages.selectProjectLabel);

  return (
    <>
      {!error && projectOptions && (
        <ComboBox
          selectedKey={[String(selectedProject.key)]}
          onChange={handleOptionChange}
          onInputValueChange={onInput}
          allowFreeInput={true}
          autoComplete="off"
          options={projectOptions}
          label={intl.formatMessage(messages.selectProjectLabel)}
          placeholder={placeholder}
          calloutProps={{
            doNotLayer: true,
            calloutMaxHeight: 300,
            calloutMinWidth: '100%' as unknown as number,
          }}
          disabled={isDisabled}
        />
      )}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
          <p>{intl.formatMessage(messages.errorLoadingProjects)}</p>
        </MessageBar>
      )}
    </>
  );
};
