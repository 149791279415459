import { create } from 'zustand';

interface Store {
  isOfficeInitialized: boolean;
  load: (bool: boolean) => void;
  conversationId: string;
  setConversationId: (id: string) => void;
}

export const useOfficeStore = create<Store>(set => ({
  isOfficeInitialized: false,
  load: (isOfficeInitialized: boolean) => set({ isOfficeInitialized }),
  conversationId: '',
  setConversationId: (id: string) => set({ conversationId: id }),
}));
