import { useIntl } from 'react-intl';
import { useOrganizationsStore } from '../store';
import messages from '../messages';
import { CSSProperties } from 'react';

export const OrganizationReadOnly = ({ style }: { style?: CSSProperties }) => {
  const currentOrg = useOrganizationsStore(
    state => state.selectedOrganizationOption,
  );
  const orgs = useOrganizationsStore(state => state.organizations);

  const org = orgs.find(o => o.id === currentOrg?.key);

  const intl = useIntl();

  return (
    <p style={style}>
      <span
        style={{
          fontWeight: 600,
          ...style,
        }}>
        {intl.formatMessage(messages.organization)}
      </span>{' '}
      {org.name}
    </p>
  );
};
