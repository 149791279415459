import { Stack } from '@fluentui/react';
import { Draft } from '../../../../features/projects/DraftProgress';
import messages from '../../../messages';
import { useIntl } from 'react-intl';
import { useDocumentsStore } from '../../../../features/documents/store';
import { useEffect } from 'react';
import { useNavState } from '../../../../app/NavState';
import { useStateRouterState } from '../../../../app/stateRouter/useStateRouterStore';

export const NewProjectSuccess = () => {
  const { templateId, organizationId } = useStateRouterState<{
    templateId: string;
    organizationId: string;
  }>();

  const intl = useIntl();

  const { setBackDisabled } = useNavState();

  useEffect(() => {
    setBackDisabled(true);
  }, [setBackDisabled]);

  if (!organizationId || !templateId) {
    window.location.replace('/project/new');
  }

  const selectedDocuments = useDocumentsStore(
    state => state.computed.selectedDocuments,
  );

  return (
    <Stack tokens={{ childrenGap: 20 }} verticalFill>
      <Stack.Item>
        <Draft
          orgId={organizationId}
          templateId={templateId}
          files={selectedDocuments}
          conversationId={Office.context?.mailbox.item?.conversationId}
          title={intl.formatMessage(messages.creatingProject)}
          updateLoading={(enabled: boolean) => {
            setBackDisabled(!enabled);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
